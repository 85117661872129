import React, { useState, useRef, useEffect } from "react";
import greenhscLogo from "../../assets/img/greenhse-logo.png";
import scan from "../../assets/img/scan.svg";
import scan_big from "../../assets/img/scan_big.svg";
import camera from "../../assets/img/camera.svg";
import document from "../../assets/img/document.svg";
import line from "../../assets/img/line.svg";
import { saveFile, getFilesStored, deleteMainFile, GetScreenShot, getPDFFile } from "../../IndexedDB.js";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./Index.css";
import { useNavigate, useLocation } from "react-router-dom";
import editIcon from '../../assets/img/edit.svg';
import DeleteIcon from "@mui/icons-material/Delete";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import Homepage from "../homepage/Index.jsx";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import Icon from '@mdi/react';
import { mdiDownload } from '@mdi/js';
import { mdiPrinter } from '@mdi/js';
import { jsPDF } from "jspdf";
import printJS from 'print-js';
import greeHseSVG from '../../assets/img/greenhseicon.svg'
import Swal from 'sweetalert2';
import greenHseSmallLogo from "../../assets/img/greenhse-logo-small.png"
import { loadLogo, convertSVGToPNG } from "../../utilities/helpers/commonHelper.js"
import { emailAndAddress } from "../config/config.js";


// Configure PDF.js worker source
const PDFJS = window.pdfjsLib;
PDFJS.disableWorker = true;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ScanerView = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const selectedProduct = location.state;
  var selectedProductList = [];
  selectedProductList.push(selectedProduct);

  // Initialize state variables
  const [run, setRun] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]); // State to store selected files
  const [error, setError] = useState("");
  const fileInputRef = useRef(null); // Reference to the file input element
  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [singleScreen, setSingleScreen] = useState(false);
  const [showHomepage, setShowHomepage] = useState(() => JSON.parse(localStorage.getItem('showHomepage')) !== false);
  const [isRunningState, setIsRunningState] = useState(false);

  // Hide homepage after 5 seconds
  useEffect(() => {
    if (showHomepage) {
      const timeoutId = setTimeout(() => {
        setShowHomepage(false);
        localStorage.setItem('showHomepage', JSON.stringify(false));
        setIsRunningState(true)
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [showHomepage]);

  // Check if user has visited before
  useEffect(() => {
    localStorage.setItem('showHomepage', JSON.stringify(true));
    const scannerViewVisited = localStorage.getItem('scannerViewVisited');
    if (scannerViewVisited) {
      setRun(false);
    }
  }, []);

  const handleSelectFloorPlan = () => {
    Swal.fire({
      // title: 'Select Floor Plan',
      text: 'Please select a floor plan with a size of 1024px or a quality of minimum 1MB for better image quality.',
      icon: 'info',
      // showCancelButton: true,
      confirmButtonText: 'OK',
      // cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // Trigger the file input click if the user confirms
        fileInputRef.current.click();
      }
    });
  };

  // Handle file changes (uploading files)
  const handleFileChange = async (event) => {
    try {
      const filesArray = Array.from(event.target.files);
      for (let i = 0; i < filesArray.length; i++) {
        const file = filesArray[i];
        const timestamp = Date.now();
        const name = `${timestamp}_${file.name}`;
        // Check if file type is PDF
        if (file.type === "application/pdf") {
          //await saveFile(name, file);

          await saveFile(name, file, []);
          // console.log(`${name}IsGridVisited`, localStorage.getItem(`${name}IsGridVisited`));
          navigate(`/editview?file=${name}`, { replace: false });
          if (localStorage.getItem(`${name.replaceAll(' ', '')}IsGridVisited`) == undefined || localStorage.getItem(`${name}IsGridVisited`) == null) {
            localStorage.setItem(`${name.replaceAll(' ', '')}IsGridVisited`, false);
          }
        } else {
          // Handle other file types (images)
          const reader = new FileReader();
          reader.onload = async () => {
            await saveFile(name, file);
            // console.log(`${name}IsGridVisited`, localStorage.getItem(`${name}IsGridVisited`));

            navigate(`/editview?file=${name}`, { replace: false });
            if (localStorage.getItem(`${name.replaceAll(' ', '')}IsGridVisited`) == undefined || localStorage.getItem(`${name}IsGridVisited`) == null) {
              localStorage.setItem(`${name.replaceAll(' ', '')}IsGridVisited`, false);
            }
            setSelectedFiles((prevFiles) => [...prevFiles, { name, file }]);
          };
          reader.readAsDataURL(file);
        }
      }
    } catch (error) {
      console.error("Error handling file change:", error);
      setError("Error handling file change");
    }
  };

  // Close the edit view popup
  const closeEditView = () => {
    setSelectedFileUrl("");
    setError("");
  };

  // Trigger file input click
  const handleScanPlan = () => {
    fileInputRef.current.click();
  };

  // Navigate to estimate plan view
  const handleEstimatePlan = (fileName) => {
    navigate(`/estimate?file=${fileName}`, { replace: false });
  }

  // Render file preview based on file type
  const renderFilePreview = (file, fileName, index) => {
    if ("name" in file || "file" in file) {
      if (
        "croppedFiles" in file &&
        (file.croppedFiles.length > 0
          ? file.name.includes("pdf")
            ? file.croppedFiles[0].name.includes("page-1")
            : true
          : false)
      ) {
        return (
          <button
            onClick={() => displayEditView(file, fileName, "")}
            key={index}
          >
            <img
              src={
                "croppedFiles" in file
                  ? file.croppedFiles.length > 0 &&
                  URL.createObjectURL(new Blob([file.croppedFiles[0].croppedFile]))
                  : URL.createObjectURL(file)
              }
              id={`download-image-${index}`}
              alt={`selected-file-${index}`}
            />
          </button>
        );
      }
      if (!("croppedFile" in file)) {
        if ("name" in file && file.name) {
          if (
            "type" in file
              ? file.type == "application/pdf"
              : file.file && file.file.type === "application/pdf"
          ) {
            const fileUrl = URL.createObjectURL(
              new Blob(["type" in file ? file : file.file], {
                type: "application/pdf",
              })
            ); // Create a blob from the file

            return (
              <button
                onClick={() => {
                  displayEditView(file.file, fileName, "");
                }}
                key={index}
              >
                <Document
                  file={fileUrl}
                  onLoadSuccess={(pdf) =>
                    console.log("Loaded a PDF")
                  }
                  onClick={() => console.log("PDF clicked")}
                >
                  <Page
                    // pageNumber={activePage+1}
                    pageNumber={1}
                    width={100}
                    height={100}
                    renderTextLayer={false}
                  />
                </Document>
              </button>
            );
          } else {
            return (
              <button
                onClick={() => displayEditView(file, fileName, "")}
                key={index}
              >
                <img
                  src={
                    "file" in file
                      ? file.file && URL.createObjectURL(file.file)
                      : URL.createObjectURL(file)
                  }
                  id={`download-image-${index}`}
                  alt={`selected-file-${index}`}
                />
              </button>
            );
          }
        } else {
          return (
            <button
              onClick={() => displayEditView(file, fileName, "")}
              key={index}
            >
              <img
                src={
                  "file" in file
                    ? file.file != undefined
                      ? URL.createObjectURL(file.file)
                      : ""
                    : URL.createObjectURL(file)
                }
                id={`download-image-${index}`}
                alt={`selected-file-${index}`}
              />
            </button>
          );
        }
      } else {
        return (
          <button
            onClick={() =>
              displayEditView(file.croppedFile, fileName, file.name)
            }
            key={index}
          >
            <img
              src={
                "croppedFile" in file
                  ? file.croppedFile != undefined
                    ? URL.createObjectURL(file.croppedFile)
                    : ""
                  : URL.createObjectURL(file)
              }
              id={`download-image-${index}`}
              alt={`selected-file-${index}`}
            />
          </button>
        );
      }
    }
  };

  // Handle file deletion
  const handleDeleteFile = async (fileName) => {
    try {
      await deleteMainFile(fileName);
      setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  // Display the edit view
  const displayEditView = async (file, mainFileName, croppedFileName) => {
    if (file.type === "application/pdf") {
      navigate(
        `/editview?file=${mainFileName}
        ${croppedFileName && "&croppedFile=" + croppedFileName}`,
        { replace: false }
      );
    } else {
      const reader = new FileReader();
      reader.onload = async (event) => {
        navigate(
          `/editview?file=${mainFileName}${croppedFileName && "&croppedFile=" + croppedFileName
          }`,
          { replace: false }
        );
      };
      reader.readAsDataURL(file.file || file);
    }
  };

  // Fetch stored files
  const fetchStorage = async () => {
    var files = await getFilesStored();
    setSelectedFiles(files);

    return true;
  };

  // Function to load the logo
  const loadLogo = async () => {
    return new Promise((resolve, reject) => {
      const logo = new Image();
      logo.src = greenHseSmallLogo;
      logo.onload = () => resolve(logo);
      logo.onerror = (err) => reject(err);
    });
  };

  // Save pdf and images to local system
  const handleDownload = async (fileName, fileData, index) => {
    let pdf = new jsPDF();
    // let greenhseLogo = await loadLogo()
    const logoDataUrl = await convertSVGToPNG(greeHseSVG);

    const pageWidth = pdf.internal.pageSize.width;
    const pageHeight = pdf.internal.pageSize.height;
    // Logo dimensions
    const logoWidth = 45;
    const logoHeight = 12.9;
    let emailAndAddressWidth = pdf.getTextWidth(emailAndAddress)

    // Calculate position to center the logo horizontally
    const logoX = (pageWidth - logoWidth) / 2;
    const logoY = 10; // Position from the top of the page
    pdf.setFont("monospace", "normal", "normal");
    pdf.setFontSize(10);
    if (fileData.file.type == "application/pdf") {
      let documentsArr = fileData.documentsForDownload
      for (const { idx, finalImage } of documentsArr) {
        if (idx > 0) {
          pdf.addPage();
        }
        pdf.addImage(logoDataUrl, 'PNG', pageWidth - 55, 5, logoWidth, logoHeight);
        pdf.text(emailAndAddress, 138, 23)
        // Add the main image
        if (finalImage.startsWith('blob:')) {
          pdf.addImage(finalImage, 'PNG', logoX - 20, logoY + logoHeight + 10, 100, 0);
        } else {
          pdf.addImage(finalImage, 'PNG', 10, logoY + logoHeight + 10, 190, 0);
          console.log("not blob found");
        }
      }
      let pdfName = 'screenshots_' + Date.now() + '.pdf';
      pdf.save(pdfName);
      // documentsArr.map(({ idx, finalImage }, index) => {
      //   if (index > 0) {
      //     pdf.addPage();
      //   }
      //   if (finalImage.startsWith('blob:')) {
      //     pdf.addImage(finalImage, 'PNG', 50, 20, 100, 200);
      //   } else {
      //     pdf.addImage(finalImage, 'PNG', 0, 0, 200, 0);
      //     console.log("not blob found");
      //   }
      // });
      // let pdfName = 'screenshots_' + Date.now() + '.pdf';
      // pdf.save(pdfName);
    } else {
      let imgDataUrl = '';
      const screenshot = await GetScreenShot(fileName, 0);
      console.log('screenshot', screenshot);
      if (Array.isArray(screenshot) && screenshot.length > 0) {
        const ss = URL.createObjectURL(screenshot.find(x => x[0].pageIndex === 0)[0].screenshotData[0].screenshot);
        console.log('ss', ss);
        imgDataUrl = ss;
      } else if (fileData.croppedFiles.length > 0) {
        let imgUrl = URL.createObjectURL(new Blob([fileData.croppedFiles[0].croppedFile]));
        imgDataUrl = imgUrl;
      } else {
        imgDataUrl = URL.createObjectURL(fileData.file);
      }
      pdf.addImage(logoDataUrl, 'PNG', pageWidth - 55, 5, logoWidth, logoHeight);
      pdf.text(emailAndAddress, 138, 23)
      pdf.addImage(imgDataUrl, 'PNG', logoX - 20, logoY + logoHeight + 10, 100, 0);
      pdf.save('img-' + Date.now());
      // const link = window.document.createElement('a');
      // link.href = imgUrl;
      // link.download = 'img-screenshot_' + Date.now();
      // link.click();
    }
  };

  // Print pdf and images
  const handlePrint = async (fileName, fileData, index) => {
    if (fileData.file.type == "application/pdf") {
      console.log("pdf print called", fileName);
      // const screenshot = await GetScreenShot(fileName, 0);
      // if (Array.isArray(screenshot) && screenshot.length > 0) {
      //   console.log('type', Array.isArray(screenshot));
      //   console.log('screenshot', screenshot);
      //   const ss = URL.createObjectURL(screenshot.find(x => x[0].pageIndex === 0)[0].screenshotData[0].screenshot);
      //   console.log('ss', ss);
      //   printJS({ printable: ss, type: 'image', header: '' });
      // } 
      // else {
      if (fileData && (fileData && fileData.documentsForDownload.length > 0)) {
        const pdf = new jsPDF();
        let documentsArr = fileData.documentsForDownload
        console.log("document arr", documentsArr);
        const currentPdfImages = [];
        for (let i = 0; i < documentsArr.length; i++) {
          currentPdfImages.push(documentsArr[i].finalImage);
        }
        console.log('currentPdfImages arr', currentPdfImages);

        // Define the header as a constant
        const headerHTML = `
      <div class="header-logo">
        <img class="header-logo-img" src=${greeHseSVG} />
        <div class="address-email">${emailAndAddress}</div>
      </div>`;

        // Combine the header with each image using a page template
        const imagesWithHeader = currentPdfImages.map(imageSrc => `
      <div class="print-page">
        ${headerHTML}
        <div class="image-container">
          <img  src="${imageSrc}" class="print-image">
          
        </div>
      </div>
    `).join('');

        // Print the combined content
        printJS({
          printable: imagesWithHeader,
          type: 'raw-html',
          documentTitle: 'GreenHse',
          style: `
          .print-page {
            page-break-after: always;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            height: 100%;
          }
          .header-logo { 
            width: 100%; 
            display: block; 
            text-align: right; 
            height:15%;
            margin-bottom: 25px; /* Adjust as necessary */
          }
          .header-logo-img { 
            max-width: 100%; 
            width:140px;
          }
          .image-container {
            width: 100%;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height:850px;
          }
          .print-image {
            max-width: 100%;
            max-height: 100%;
            width:85%;
          }
        `,
        });
      }
      // }
    } else {
      console.log("Called image print", fileName);
      const screenshot = await GetScreenShot(fileName, 0);
      let imgPrintUrl = '';
      if (Array.isArray(screenshot) && screenshot.length > 0) {
        imgPrintUrl = URL.createObjectURL(screenshot.find(x => x[0].pageIndex === 0)[0].screenshotData[0].screenshot);
        // printJS({ printable: ss, type: 'image', header: '' });
      } else if (fileData.croppedFiles.length > 0) {
        console.log('fileData.croppedFiles', fileData.croppedFiles);
        imgPrintUrl = URL.createObjectURL(new Blob([fileData.croppedFiles[0].croppedFile]));
        // printJS({ printable: imgUrl, type: 'image', header: '' })
      } else {
        console.log('file Url ', URL.createObjectURL(fileData.file));
        imgPrintUrl = URL.createObjectURL(fileData.file)
        // printJS({ printable: URL.createObjectURL(fileData.file), type: 'image', header: '' })
      }

      // Define the header as a constant
      const headerHTML = `
                <div class="print-page" >
                  <div class="header-logo">
                    <img class="header-logo-img" src=${greeHseSVG} />
                    <div class="address-email">${emailAndAddress}</div>
                  </div>
       
                <div class="image-container">
                  <img  src="${imgPrintUrl}" class="print-image">
                </div>
        </div >`;


      // printJS({ printable: imgData, type: 'image', documentTitle: 'GreenHse', header: '' });
      printJS({
        printable: headerHTML,
        type: 'raw-html',
        documentTitle: 'GreenHse',
        style: `
    .print-page {
      page-break-after: always;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      height: 100%;
    }
    .header-logo { 
      width: 100%; 
      display: block; 
      text-align: right; 
      height:15%;
      margin-bottom: 25px; /* Adjust as necessary */
    }
    .header-logo-img { 
      max-width: 100%; 
      width:140px;
    }
    .image-container {
      width: 100%;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height:850px;
    }
    .print-image {
      max-width: 100%;
      max-height: 100%;
      width:85%;
    }
  `,
      });
    }
  }

  useEffect(() => {
    new Promise(async (resolve, reject) => {
      var isSuccess = fetchStorage();
      if (isSuccess) resolve("done");
      else reject("cancel");
    });
    //functionality to set collapsible table
    window.addEventListener("resize", updateDimension);
    updateDimension();
    //Remove some items from local storage which are used inside edit view
    localStorage.removeItem("activePageIndex");
    localStorage.removeItem("currentNodePositions");
    localStorage.removeItem("isGridEnable");
    // removing eventlistener for no furhter effect after
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, []);

  // Update screen size on window resize
  const updateDimension = () => {
    setScreenSize(getCurrentDimension());
    var value = getCurrentDimension();
    // set a variable true when screen width reaches <768px
    if (value.width <= 767) {
      setSingleScreen(true);
    } else {
      setSingleScreen(false);
    }
  };

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  // Code for Guided tours
  const [steps, setSteps] = useState([
    {
      target: '.upload-img-pdf',
      content: 'By clicking on camera or document you can upload your pdf or image in jpg or png',
      disableBeacon: true,
      hideCloseButton: true,
      debug: true
      // disableScrolling: true,
      // disableScrollParentFix: true
    }
  ])

  // joyride callback function
  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // You need to set our running state to false, so we can restart if we click start again.
      localStorage.setItem('scannerViewVisited', 'true');
      setRun(false);
    }
  };

  return (
    <div>
      <div className="scanner-view-container">
        {showHomepage ? (
          <>
            <Homepage />
            <Joyride
              callback={isRunningState && handleJoyrideCallback}
              steps={steps}
              run={run}
              styles={{
                options: {
                  primaryColor: '#5CB226',
                  height: 'auto !important'
                },
              }}
              disableOverlayClose={true}
            />

          </>  // Show Homepage if showHomepage is true
        ) : (
          <>
            <Joyride
              callback={handleJoyrideCallback}
              steps={steps}
              run={run}
              styles={{
                options: {
                  primaryColor: '#5CB226',
                  height: 'auto !important'
                },
              }}
              disableOverlayClose={true}
            />
            <div>

              <div className="greenhse-logo">
                <img src={greenhscLogo} alt="right-icon" />
              </div>

              <div className="greenhsce-content-block">
                <div className="greenhsce-content-text">
                  <span>
                    <p>
                      Upload a single or multi-floor plan in PDF, JPG, or PNG to start
                      your estimate
                    </p>
                  </span>
                  <span>
                    <img src={scan} alt="right-icon" />
                  </span>
                </div>
              </div>
              <div className="heading-scan-view"><p>Estimated floor plannings</p></div>

              {/* Conditionally render the scan_big image */}
              {selectedFiles && selectedFiles.length === 0 && (
                <div className="scanbig-img-block">
                  <img src={scan_big} alt="right-icon" />
                </div>
              )}

              {/* Display selected files */}
              {selectedFiles && selectedFiles.length > 0 && (
                <div className="file-upload-block row">
                  {selectedFiles.map((file, index) => (
                    <>
                      <div key={index} className="card upload-file-block-custom col-lg-4 col-md-6 col-sm-12">
                        <div className="card-body upload-file-block">
                          <div className="card-upload-img-block">
                            {file &&
                              renderFilePreview(
                                file,
                                file && "name" in file ? file.name : file.file.name,
                                index
                              )}
                          </div>
                          <div className="card-content-block">
                            <h5 className="card-title">
                              {file && "name" in file
                                ? file.estimateName
                                : file.file.estimateName}
                            </h5>
                            <div className="edit-delete-block">

                              <div
                                className="edit-icon"
                                onClick={() =>
                                  displayEditView(
                                    file,
                                    file && "name" in file ? file.name : file.file.name,
                                    ""
                                  )
                                }
                              >
                                <img src={editIcon} alt="right-icon" />
                              </div>
                              <div className="delete-icon" onClick={() =>
                                handleDeleteFile(
                                  file && "name" in file ? file.name : file.file.name
                                )}>
                                <DeleteIcon />
                              </div>
                              <div className="delete-icon" onClick={() =>
                                handleEstimatePlan(file && "name" in file ? file.name : file.file.name
                                )}>
                                <PointOfSaleIcon />
                              </div>
                              <div className="" onClick={() =>
                                handleDownload(file && "name" in file ? file.name : file.file.name, file, index
                                )}>
                                <Icon path={mdiDownload} size={1} />
                              </div>
                              <div className="print-icon" onClick={() =>
                                handlePrint(file && "name" in file ? file.name : file.file.name, file, index
                                )}>
                                <Icon path={mdiPrinter} size={1} />
                              </div>
                            </div>
                          </div>

                          <div>
                            {/* <h5 className="card-title">
                      {file && "name" in file
                        ? file.estimateName
                        : file.file.estimateName}
                    </h5> */}
                          </div>

                        </div>
                      </div>
                    </>
                  ))}
                </div>
              )}

              {/* Button for selecting files */}
              <div className="select-button-custom-block">
                {singleScreen ? (
                  <button className="button-block" onClick={handleScanPlan}>
                    <img src={camera} alt="right-icon" />
                    <img src={line} alt="right-icon" />
                    <img src={document} alt="right-icon" />
                  </button>
                ) : (
                  <button className="button-block select-button-full-width upload-img-pdf" onClick={handleSelectFloorPlan}>
                    <img src={document} alt="right-icon" />
                    <p>Select floor plan{" "}</p>
                  </button>
                )}
                <input
                  ref={fileInputRef}
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  multiple
                />{" "}
                {/* Enable multiple file selection */}
              </div>

              {selectedFiles && selectedFileUrl && (
                <div className="editview-popup">
                  <button onClick={closeEditView}>Close</button>
                  {error && <div>Error: {error}</div>}
                  {selectedFileUrl && (
                    <iframe
                      className="popup-iframe-block"
                      title="PDF Viewer"
                      src={selectedFileUrl}
                      style={{ width: "100%", height: "calc(100vh - 100px)" }}
                      frameBorder="0"
                    ></iframe>
                  )}
                  {!selectedFileUrl && !error && <div>Loading...</div>}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ScanerView;
